@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:
    'Public Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #979797;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.tab-panel .data-table-container {
  min-height: 545px;
}

.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(4),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(5),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(6),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(7),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(8),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(13),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(14),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(15),
.all-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(16) {
  background-color: #f4f9ff;
}

.risk-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(4),
.risk-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(5),
.risk-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(6),
.risk-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(7),
.risk-view-table > div:first-child > .rdt_Table > .rdt_TableHead > .rdt_TableHeadRow > .rdt_TableCol:nth-child(8) {
  background-color: #f4f9ff;
}

.informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(4),
.informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(5),
.informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(6),
.informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(7) {
  background-color: #f4f9ff;
}

.sending-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(8),
.sending-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(9),
.sending-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(10),
.sending-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(11) {
  background-color: #f4f9ff;
}

.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(4),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(5),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(6),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(7),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(8),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(9),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(10),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(11),
.risk-and-informations-view-table
  > div:first-child
  > .rdt_Table
  > .rdt_TableHead
  > .rdt_TableHeadRow
  > .rdt_TableCol:nth-child(12) {
  background-color: #f4f9ff;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

/**
 * overwrite data table styles for actions columns overlay on batch list: ORI-356
 */
.rdt_TableBody {
  min-height: 480px !important;
}
.batch-list .rdt_TableCol:last-of-type,
.declaration-list .rdt_TableCol:last-of-type,
.batch-list .rdt_TableCell:last-of-type,
.declaration-list .rdt_TableCell:last-of-type {
  position: sticky;
  right: 0;
  padding: 21px;
  width: 145px;
  background: white;
  box-shadow: -2px 0px 3px 0px rgba(72, 111, 161, 0.25);
  z-index: 1;
}

.declaration-list .rdt_TableCol:last-of-type,
.declaration-list .rdt_TableCell:last-of-type {
  max-width: 130px;
  padding: 0 21px;
}

.batch-list .rdt_TableCell:last-of-type {
  padding: 13px;
}

/* consume the extra space; allow the overlay column to remain on the right */
.batch-list .rdt_TableCol:nth-last-child(2),
.batch-list .rdt_TableCell:nth-last-child(2) {
  min-width: 0;
  padding: 0;
  margin: 0;
}

.rdt_TableRow:hover,
.rdt_TableRow:hover .rdt_TableCell {
  background: #e8f3fc;
}
.rdt_TableRow .rdt_TableCell:first-of-type {
  border-left: 10px solid white;
}
.rdt_TableRow:hover .rdt_TableCell:first-of-type {
  border-color: #88c8e3;
}
.rdt_TableHead .rdt_TableHeadRow {
  border-bottom-color: #486fa1;
}

/* when the context menu is opened,
the row that contains it must have a higher z-index than the next rows,
to allow the context menu to be displayed on top */
.menu-open {
  z-index: 2 !important;
}

/* menu item link: ORI-373 */
.rdt_TableCell .szh-menu__item {
  cursor: inherit;
  padding: 0;
}

.rdt_TableCell .szh-menu__item button.svg-icon.with-text {
  display: flex;
  padding: 1rem 1.5rem;
  width: 100%;
  height: auto;
  justify-content: flex-start;
}

.Toastify {
  position: absolute;
  z-index: 10002;
}
